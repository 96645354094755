import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { ContainerColumn } from '@components/styled';
import BlockContainer from '@shared/ui/settings/SettingsBlock';
import ApiKeysManagement from './components/ApiKeysManagement';
import MainSettings from './components/MainSettings';
import ProfileManagement from './components/ProfileManagement';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const SettingsPersonalInformation = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx(BlockContainer, { title: t('settings.tabs.personal_info.main_table.title'), gap: theme.spacing_sizes.m, padding: `${theme.spacing_sizes.l}px ${theme.spacing_sizes.s * 5}px`, children: _jsx(MainSettings, {}) }), _jsx(BlockContainer, { title: t('settings.tabs.personal_info.api_keys.title'), gap: theme.spacing_sizes.m, padding: `${theme.spacing_sizes.l}px ${theme.spacing_sizes.s * 5}px`, children: _jsx(ApiKeysManagement, {}) }), _jsx(BlockContainer, { title: t('settings.tabs.personal_info.profile_management_table.title'), gap: theme.spacing_sizes.m, padding: `${theme.spacing_sizes.l}px ${theme.spacing_sizes.s * 5}px`, children: _jsx(ProfileManagement, {}) })] }));
};
export default SettingsPersonalInformation;
